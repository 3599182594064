import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Container, Icon } from "@atoms";
import { IntentionCard } from "@molecules";
import useIntentionsData from "@staticQueries/IntentionGlobalQuery";
import useLang from "@hooks/useLang";
import { m, AnimatePresence } from "framer-motion";
import t from "@utils/t";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useWindowSize } from "@utils";

const IntentionCardsContainer = ({
  heading,
  cards,
  sectionState,
  cardIndex,
}) => {
  const [sectionOpen, setSectionOpen] = sectionState;
  const [selected, setSelected] = useState(0);
  const pageLang = useLang();
  const { dailyIntentions, shareCopy } = useIntentionsData()[pageLang || "en"];
  // refs
  const scroll = useRef();
  const { innerWidth: windowWidth } = useWindowSize();
  // screen size
  const screens = {
    sm: "700px", // bigger than most phones
    md: "850px",
    lg: "1200px",
    xl: "1600px", // larger than 15" macbook pro
    xxl: "2000px",
  };

  const calcScreen = width => {
    const screenNumbers = {};
    Object.keys(screens).map(screen => {
      if (typeof screens[screen] === "string") {
        screenNumbers[screen] = parseInt(screens[screen].replace("px", ""), 10);
      }
      return true;
    });
    // match screen
    const matchedScreen = Object.keys(screenNumbers).find(screen => {
      return width < screenNumbers[screen];
    });
    return matchedScreen;
  };

  // const getWhichIntention = amount => {
  //   const presentDay = new Date();
  //   const endDay = new Date(2023, 3, 1, 0, 0, 0);
  //   const dayInMilliseconds = 1000 * 60 * 60 * 24;

  //   const timeDiff = Math.floor(endDay.getTime() - presentDay.getTime());
  //   const days = Math.round(timeDiff / dayInMilliseconds);

  //   // additional 1 to account for the index
  //   const remaining = amount - days - 1;

  //   if (remaining < 0) return 0;
  //   if (remaining > 25) return 25;
  //   return remaining;
  // };
  // const whichIntention = getWhichIntention(dailyIntentions.length);

  useEffect(() => {
    const newSlides = calcScreen(windowWidth);

    if (scroll.current) {
      if (sectionOpen && newSlides === "sm") {
        disableBodyScroll(scroll.current);
      } else {
        enableBodyScroll(scroll.current);
      }
    }
  }, [sectionOpen, windowWidth]);

  useEffect(() => {
    requestAnimationFrame(() => {
      const newSlides = calcScreen(windowWidth);
      if (newSlides !== "sm") enableBodyScroll(scroll.current);
    });
  }, [windowWidth]);

  return (
    <AnimatePresence>
      {sectionOpen && (
        <m.section
          className="fixed inset-0 flex h-[100vh] w-full flex-col justify-center pt-24 sm:relative sm:max-h-[auto] sm:min-h-[99vh] sm:justify-start lg:pt-32"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.3 }}
        >
          <Container variant="xl">
            {heading && (
              <h2
                className={classNames(
                  "text-md block text-center font-neutral font-bold uppercase leading-none tracking-0 transition duration-300 md:text-2xl",
                  {
                    "opacity-0": selected !== -1,
                  }
                )}
              >
                {heading}
              </h2>
            )}
          </Container>

          <div>
            {cards?.length > 0 && (
              <>
                <div
                  ref={scroll}
                  className={classNames("pb-32 sm:px-10 sm:pb-10 md:px-0", {
                    "overflow-x-scroll": selected === -1,
                  })}
                >
                  <div className="mx-auto mt-12 flex w-max gap-4 px-2 sm:grid sm:max-w-3xl sm:grid-cols-intention-cards-sm sm:justify-center sm:px-0 lg:grid-cols-intention-cards lg:gap-8">
                    {cards.map((card, i) => {
                      return (
                        <IntentionCard
                          key={card.uid}
                          pageLang={pageLang}
                          intention={dailyIntentions?.[card.cardIndex]}
                          {...card}
                          i={i}
                          cardState={[selected, setSelected]}
                          sectionState={[sectionOpen, setSectionOpen]}
                          introCard={i < 5}
                          shareCopy={shareCopy}
                        />
                      );
                    })}
                  </div>
                </div>
                <Container className="pointer-events-none block -translate-y-24 sm:hidden">
                  <span
                    className={classNames(
                      "pointer-events-none font-neutral text-xs font-bold uppercase",
                      {
                        "opacity-0": selected !== -1,
                      }
                    )}
                  >
                    {t("Scroll", pageLang)}{" "}
                    <Icon name="arrow" className="w-4" />
                  </span>
                </Container>
              </>
            )}
          </div>
        </m.section>
      )}
    </AnimatePresence>
  );
};

export default IntentionCardsContainer;
